import category from '@/api/category'
import * as types from '@/store/mutation-types'

const state = {
  all: []
}

const getters = {
  allCategories: state => state.all
}

const actions = {
  async getAllCategories ({ commit }) {
    let categories = await category.getCategories()
    categories = categories.data

    commit(types.RECEIVE_CATEGORIES, { categories })
  }
}

// mutations
const mutations = {
  [types.RECEIVE_CATEGORIES] (state, { categories }) {
    state.all = categories
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
