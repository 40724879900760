<template>
  <div class="rent-items">
    <clip-loader :loading="!items" :color="color" :size="size"></clip-loader>
    <div class="rent-items-table">
      <table v-if="items.length">
        <tr>
          <td>Liczba wynajęć</td>
          <td>Nazwa</td>
          <td>Cena</td>
          <td>Rozliczenie</td>
          <td>Status</td>
              </tr>
              <tr v-for="item in items" :key="item.id">
          <td>{{ item.item_rented_count }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.rent_price }} zł</td>
          <td v-if="item.rent_type_name == 'hourly'">Godzinowo</td>
          <td v-else-if="item.rent_type_name == 'daily'">Dzienne</td>

          <td v-if="item.rent_status_name == 'available'" style="color: green">Do wynajęcia</td>
          <td v-else-if="item.rent_status_name == 'reservated'" style="color: orange">Zarezerwowane</td>
          <td v-else style="color: red">Wynajęte</td>
        </tr>
      </table>
    </div>
  <i align="center">Należy rezerwować sprzęt telefonicznie.</i>
  </div>
</template>

<style type="text/css" scoped>
  .rent-items-table {
    overflow-x:auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #cecece;
    margin-bottom: 1em;
  }

  tr:first-child {
    font-weight: bold;
  }

  td {
    padding: .5em;
    border: 1px solid #cecece;
  }
  @media screen and (min-width: 768px) {
    .rent-items {
      margin: 3em;
    }
    .rent-items-table {
      overflow-x:auto;
    }
  }
</style>

<script type="text/javascript">
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters({
    items: 'allItems'
  }),

  data() {
    return {
      sortKey: 'name',
      reverse: false,
      color: '#cc181e',
      color1: '#5bc0de',
      size: '45px',
      margin: '2px',
      radius: '2px'
    }
  },
  components: {
    ClipLoader
  },
  methods: {
    sortBy (sortKey) {
      this.reverse = (this.sortKey === sortKey) ? !this.reverse : false
      this.sortKey = sortKey
    }
  },

  created () {
    this.$store.dispatch('getAllItems', this.$route.params.id)
  }
}
</script>
