import item from '@/api/item'
import * as types from '../mutation-types'

// initial state
const state = {
  all: []
}

// getters
const getters = {
  allItems: state => state.all
}

// actions
const actions = {
  async getAllItems ({ commit }, id) {
    let items = await item.getItems(id)
    items = items.data

    commit(types.RECEIVE_ITEMS, { items })
  }
}

// mutations
const mutations = {
  [types.RECEIVE_ITEMS] (state, { items }) {
    state.all = items
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
