<template>
    <div class="home">
        <p>
            Jesteśmy firmą która zajmuje się naprawą oraz wynajmem sprzętu
            ogrodniczego <br />
            jak i również budowlanego. Nasze wieloletnie doświadczenie zbudowane
            jest na sumienności, rzetelności oraz jak największym zadowoleniu
            klienta.
        </p>
    </div>
</template>

<style scoped>
.home {
    height: 100%;
    background:
        linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("../assets/strona tytułowa.jpg") no-repeat fixed center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home p {
    text-align: center;
    margin: 3em;
    font-size: 2em;
    color: #fff;
}
@media screen and (max-width: 480px) {
    .home p {
        text-align: left;
        font-size: 1em;
    }
}
</style>

<script>
export default {
    head: {
        // To use "this" in the component, it is necessary to return the object through a function
        title: {
            inner: "Mirgo Serwis",
        },
        meta: [
            {
                name: "description",
                content:
                    "Naprawa i wynajm sprzętu ogrodniczego i budowlanego w Gdańsku",
            },
            {
                name: "keywords",
                content:
                    "narzedzia,narzędzia,serwis,kosiarki,kosiarka,sprzęt,sprzet,budowlany,naprawa,wynajm,gdansk,gdańsk",
            },
        ],
        script: [
            {
                async: true,
                src: "https://umami.wasteland.co/script.js",
                "data-website-id": "e4b686bd-2799-4b85-aa06-b62afb9eddaa",
                "data-domains": "mirgo-serwis.pl",
            },
        ],
    },
};
</script>
