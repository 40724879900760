import Vue from "vue";
import Router from "vue-router";
import VueHead from "vue-head";
import vueConfig from "@/modules/config_loader";
import AboutUs from "@/components/AboutUs";
import Rents from "@/components/rents/Rents";
import RentsCategories from "@/components/rents/Categories";
import WhatWeRepair from "@/components/WhatWeRepair";
import Contact from "@/components/Contact";
const config = require(`@/config/${process.env.NODE_ENV}`);
import VTooltip from "v-tooltip";

Vue.use(vueConfig, config);
Vue.use(VueHead, {
  inner: "My title",
  separator: "-",
  complement: "Mirgo Serwis",
});
Vue.use(Router);
Vue.use(VTooltip);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "AboutUs",
      component: AboutUs,
    },
    {
      path: "/rents/category",
      name: "RentsCategories",
      component: RentsCategories,
    },
    {
      path: "/rents/category/:id",
      name: "Rents",
      component: Rents,
    },
    {
      path: "/what_we_repair",
      name: "WhatWeRepair",
      component: WhatWeRepair,
    },
    {
      path: "/contact",
      name: "Contact",
      component: Contact,
    },
  ],
});
