<template>
    <div class="contact">
        <l-map id="mapid" :zoom="zoom" :center="center" @ready="mapReady">
            <l-tile-layer :url="url"></l-tile-layer>
            <l-layer-group>
                <l-marker ref="popz" :lat-lng="markerLatLng">
                    <l-popup><b>Jesteśmy tutaj</b></l-popup>
                </l-marker>
            </l-layer-group>
        </l-map>
        <div class="info">
            <h3>Kontakt</h3>
            <div>
                <a href="tel:+48506707822">
                    <font-awesome-icon icon="phone" /> 506 707 822
                </a>
            </div>
            <div>
                <font-awesome-icon icon="map-marker" /> Reformacka 9, 80-808
                Gdańsk
            </div>
        </div>
    </div>
</template>

<style scoped>
.contact {
    position: relative;
    padding: 0;
    height: 100%;
}

#mapid {
    width: 100%;
    height: 100%;
    z-index: 5;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover {
    color: #fff;
}

.info {
    text-align: left;
    padding: 0em 3em 0 3em;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 100%;
    top: 0;
    left: 3em;
    z-index: 10;
}

.info h3 {
    margin: 2em 0 0 0;
}

.info div {
    padding-top: 1em;
}
@media screen and (max-width: 780px) {
    #mapid {
        height: 15em;
    }
    .info {
        background: #000;
        padding: 1em;
        height: auto;
        position: static;
    }
    .info h3 {
        margin: 0;
    }
}
</style>

<script>
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
    name: "contact",
    head: {
        title: {
            inner: "Kontakt",
        },
        meta: [
            {
                name: "description",
                content:
                    "Naprawa i wynajm sprzętu ogrodniczego i budowlanego w Gdańsku",
            },
            {
                name: "keywords",
                content:
                    "narzedzia,narzędzia,serwis,kosiarki,kosiarka,sprzęt,sprzet,budowlany,naprawa,wynajm,gdansk,gdańsk",
            },
        ],
        script: [
            {
                async: true,
                src: "https://umami.wasteland.co/script.js",
                "data-website-id": "e4b686bd-2799-4b85-aa06-b62afb9eddaa",
                "data-domains": "mirgo-serwis.pl",
            },
        ],
    },
    data() {
        return {
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            zoom: 18,
            center: [54.3425, 18.6300024],
            markerLatLng: [54.34253, 18.630216],
        };
    },
    methods: {
        mapReady() {
            this.$refs.popz.mapObject.openPopup([54.34253, 18.630216]);
        },
    },
};
</script>
