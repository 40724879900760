<template>
  <div id="app">
    <div class="top">
      <header>
        <h1><router-link :to="{ name: 'AboutUs' }">MIRGO</router-link></h1>
      </header>
    </div>
    <div class="content">
      <transition name="slide">
        <router-view></router-view>
      </transition>
    </div>

    <nav id="nav" class="nav">
      <ul>
        <li><router-link :to="{ name: 'WhatWeRepair' }">CO NAPRAWIAMY</router-link></li>
        <li><router-link :to="{ name: 'Contact' }">KONTAKT</router-link></li>
        <li class="to_nav"><a href="#">TOP</a></li>
      </ul>
    </nav>
    <div class="bottom">
      <footer>
        Copyright &copy; MIRGO
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  methods: {
  },
  mounted () {
  }
}
</script>

<style>
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0b1 | 201101
   NOTE: WORK IN PROGRESS
   USE WITH CAUTION AND TEST WITH ABANDON */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

/* remember to highlight inserts somehow! */
ins {
    text-decoration: none;
}
del {
    text-decoration: line-through;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
html, body {
  width: 100%;
  height: 100%;
}
ul {
  list-style: disc;
}
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.top {
  letter-spacing: 0.1em;
  line-height: 2em;
  padding: 0 3em 1em 3em;
}
.top header {
  font-size: 2em;
}
.top nav,header {
  margin: 1em 0 0 0;
}

.content {
  height: 100%;
}

#nav ul {
  list-style: none;
  background: #1c1c1c;
  padding: 5px 0;
}

#nav li a {
  text-align: left;
  display: block;
  padding: 0 20px;
  color: #fff;
  text-decoration: none;
  letter-spacing: 0.1em;
  line-height: 2em;
  height: 2em;
  border-bottom: 1px solid #383838;
}

#nav li:last-child a {
  border-bottom: none;
}

#nav li a:hover,
#nav li a:focus {
  color: #1c1c1c;
  background: #ccc;
}
.top a {
  color: #000;
  text-decoration: none;
  border-bottom: 1px dotted transparent;
  transition: border .25s ease-in-out;
}
.top a:hover {
  border-bottom: 5px solid #1fe500;
  transition: border .25s ease-in-out;
}
.bottom {
  background: rgba(206,206,206,0.3);
  box-shadow: 0 0 0 1px #cecece;
  font-size: 0.75em;
}
.bottom footer {
  padding: 1em 0 1em 0;
}
@media screen and (min-width: 768px) {

  .top {
    height: 3em;
    margin-bottom: 2em;
  }

  #nav {
    position: absolute;
    top: 1em;
    right: 2em;
    background: none;
  }

  #nav ul {
    background: none;
  }

  #nav li {
    display: inline;
  }

  #nav .to_nav {
    display: none;
  }

  #nav li a {
    float: left;
    padding: 0 1em;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: none;
    color: #000;
    text-decoration: none;
    border-bottom: 1px dotted transparent;
    transition: border .25s ease-in-out;
  }
  #nav li a:hover,
  #nav li a:focus {
    background: none;
  }
  #nav li a:hover {
    border-bottom: 5px solid #1fe500;
    transition: border .25s ease-in-out;
  }
}
</style>
