<template>
<div class="categories">
  <clip-loader :loading="!categories.length" :color="color" :size="size"></clip-loader>
  <router-link v-for="category of categories" :key="category.id" class="category" :to="{ name: 'Rents', params: { id: category.id }}">
    <img :src="category.background_image_url" />
    <h3>{{category.name}}</h3>
  </router-link>
</div>
</template>

<style scoped>
.categories {
    display: flex;
    justify-content: center;
}
.category {
    background: #000;
    height: 100%;
    position: relative;
}
.category img {
    opacity: 0.5;
    width: 100%;
    transition: opacity .5s ease-in-out;
    object-fit: cover;
}
.category img:hover {
    opacity: 1;
    transition: opacity .5s ease-in-out;
}
.category h3 {
    color: #fff;
    position: absolute;
    left: 100px;
    bottom: 0;
    padding: 1em;
    background: rgba(0,0,0,0.7);
}
@media screen and (max-width: 780px) {
  .category {
      width: 100%;
      height: 10em;
      background: none;
  }
  .category img {
      opacity: 1;
      height: 10em;
      transition: none;
  }
  .category img:hover {
      transition: none;
      background: none;
  }
  .category h3 {
      left: 0;
  }
}
</style>

<script type="text/javascript">
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters({
    categories: 'allCategories'
  }),
  data () {
    return {
      color: '#cc181e',
      color1: '#5bc0de',
      size: '45px',
      margin: '2px',
      radius: '2px'
    }
  },
  components: {
    ClipLoader
  },
  created () {
    this.$store.dispatch('getAllCategories')
  }
}
</script>
