<template>
    <div class="what-we-repair">
        <div>
            <p>Zajmujemy się naprawą:</p>
            <ul>
                <li>Kosiarek spalinowych i elektrycznych</li>
                <li>Pił łańcuchowych</li>
                <li>Wykaszarkek</li>
                <li>Agregatów prądotwórczych</li>
                <li>Młotów udarowych</li>
                <li>Wiertarek</li>
                <li>Szlifierek</li>
                <li>Glebogryzarek</li>
                <li>Pilarek elektrycznych i spalinowych</li>
                <li>Kos, podcinarek spalinowych i elektrycznych</li>
                <li>Inne</li>
            </ul>
            <p>Naprawiamy sprzęty takich marek jak :</p>
            <div class="companies">
                <img src="../assets/BGG.png" />
                <img src="../assets/black&decker.png" />
                <img src="../assets/Bosch-logo.png" />
                <img src="../assets/hilti.png" />
                <img src="../assets/husqvarna.png" />
                <img src="../assets/makita-logo.jpg" />
                <img src="../assets/stihl.jpg" />
                <img src="../assets/tecumsech.png" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.content {
    min-height: 100%;
}
.what-we-repair ul {
    padding: 1em 0 1em 1em;
}
.what-we-repair {
    text-align: left;
    font-size: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 2em 0 2em;
}
.companies {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.companies > img {
    max-width: 10%;
    height: auto;
    object-fit: contain;
}
@media screen and (max-width: 768px) {
    .what-we-repair {
        font-size: 1em;
    }
    .companies > img {
        max-width: 20%;
    }
    .bottom {
        margin-top: 1em;
    }
}
</style>

<script>
export default {
    head: {
        title: {
            inner: "Co naprawiamy",
        },
        meta: [
            {
                name: "description",
                content:
                    "Naprawa i wynajm sprzętu ogrodniczego i budowlanego w Gdańsku",
            },
            {
                name: "keywords",
                content:
                    "narzedzia,narzędzia,serwis,kosiarki,kosiarka,sprzęt,sprzet,budowlany,naprawa,wynajm,gdansk,gdańsk",
            },
        ],
        script: [
            {
                async: true,
                src: "https://umami.wasteland.co/script.js",
                "data-website-id": "e4b686bd-2799-4b85-aa06-b62afb9eddaa",
                "data-domains": "mirgo-serwis.pl",
            },
        ],
    },
};
</script>
